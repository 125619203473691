import type { GraphQLFormattedError } from 'graphql';

import { GraphqlError } from './GraphqlError.js';
import { FetchError } from './FetchError.js';

export interface GraphqlResponse<R> {
    data?: R;
    errors?: GraphQLFormattedError[];
}

export interface GraphqlClientOptions<SDK> {
    url: string;
    headers?: Record<string, string>;
    sdk: (requester: GraphqlRequester) => SDK;
}

interface GraphqlRequester {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (doc: string, vars: unknown): Promise<any>;
}

export function grapqhlClient<SDK>(options: GraphqlClientOptions<SDK>) {
    const requester: GraphqlRequester = async (doc, vars) => {
        const response = await fetch(options.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
            body: JSON.stringify({
                query: doc,
                variables: vars,
            }),
        });

        if (!response.ok) {
            throw new FetchError(response);
        }

        const result = (await response.json()) as GraphqlResponse<unknown>;

        if (result.errors) {
            throw new GraphqlError(result.errors);
        }

        if (result.data === undefined || result.data === null) {
            throw new Error('No data presented in the GraphQL response');
        }

        return result.data;
    };

    return options.sdk(requester);
}
